import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import Nav from "./Nav/Nav";
import Hamburger from "./Hamburger/Hamburger";
import Footer from "./Footer/Footer";

import "./layout.css";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";

  switch (lang) {
    case "it":
      return "/it";
    default:
      return "";
  }
};

const Layout = ({ home, children, pageUrl }) => {
  const [isOpen, setOpen] = useState(false);

  /** Redirect based on browser language */
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    navigate(`${urlLang}/${pageUrl}`, { replace: true });
  }, [pageUrl]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        height: isOpen || home ? "100%" : "auto",
      }}
    >
      <Hamburger isOpen={isOpen} setOpen={setOpen} />
      <Nav home={home} isOpen={isOpen} setOpen={setOpen} />
      <main>{children}</main>
      {!home && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,

  /** Whether the location is home page */
  home: PropTypes.bool,
};

Layout.defaultProps = {
  pageUrl: "",
};

export default Layout;
