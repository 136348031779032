import React from "react";

import Logo from "../Logo/Logo";
import Linkedin from "./icons/Linkedin";
import Instagram from "./icons/Instagram";

import styles from "./Footer.module.css";

const Footer = ({ home }) => (
  <footer
    className={styles.Footer}
    style={{ display: home ? "none" : "block" }}
  >
    <div className={styles.FooterContainer}>
      <div className={styles.FooterLogo}>
        <a href="/">
          <Logo />
        </a>
      </div>
      <div className={styles.FooterInfo}>
        <p>Parallel Milano SRL</p>
        <p>Via Brescia 52 - 20063 - Cernusco Sul Naviglio (MI)</p>
        <p>Cod. Fisc. e Part. IVA IT09325410968</p>
        <p>
          <a href="mailto:contact@parallelmilano.com">
            contact@parallelmilano.com
          </a>
        </p>
      </div>
      <div className={styles.FooterSocial}>
        <a
          href="https://www.instagram.com/parallelmilano/"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram />
        </a>
        <a
          href="https://www.linkedin.com/company/parallelmilano/"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
