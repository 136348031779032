import React from "react";

function Instagram() {
  return (
    <svg viewBox="0 0 31.5 31.5">
      <path
        data-name="instagram"
        d="M29.25 31.5H2.243A2.259 2.259 0 0 1 0 29.229V2.271A2.259 2.259 0 0 1 2.243 0H29.25a2.263 2.263 0 0 1 2.25 2.271v26.958a2.263 2.263 0 0 1-2.25 2.271zM10.854 4A6.874 6.874 0 0 0 4 10.854v9.791a6.874 6.874 0 0 0 6.854 6.855h9.791a6.874 6.874 0 0 0 6.855-6.855v-9.791A6.874 6.874 0 0 0 20.645 4zm9.791 21.541h-9.791a4.91 4.91 0 0 1-4.9-4.9v-9.787a4.91 4.91 0 0 1 4.9-4.9h9.791a4.91 4.91 0 0 1 4.9 4.9v9.791a4.91 4.91 0 0 1-4.9 4.9zM15.75 10a5.75 5.75 0 1 0 5.75 5.75A5.757 5.757 0 0 0 15.75 10zm6.229-2.167a1.688 1.688 0 1 0 1.687 1.687 1.689 1.689 0 0 0-1.687-1.687zm-6.229 11.75a3.834 3.834 0 1 1 3.833-3.833 3.838 3.838 0 0 1-3.833 3.833z"
      />
    </svg>
  );
}

export default Instagram;
