import React from "react";
import { Link } from "gatsby";

import styles from "./Nav.module.css";

const Nav = ({ isOpen }) => {
  const navPopupClass = isOpen ? styles.isOpen : "";

  return (
    <nav className={`${styles.Nav} ${navPopupClass}`}>
      <ul className={styles.NavList}>
        <Link to="/">
          <li>Home</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/ecommerce/">
          <li>E-commerce</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/virtual-showroom/">
          <li>Virtual Showroom</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/lookbook/">
          <li>Lookbook</li>
        </Link>
        <Link to="/social-media-project/">
          <li>Social Media Project</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/video-making/">
          <li>Video Making</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/post-production/">
          <li>Post production</li>
        </Link>
        <li className={styles.NavListSeparator}>-</li>
        <Link to="/about/">
          <li>About</li>
        </Link>
      </ul>
    </nav>
  );
};

export default Nav;
